import React, { useEffect, useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import style from "./Interviu.module.css";
import Title from "../../assets/images/titlu_interviuri.png";
import axios from "axios";
import { API_URL } from "../../const";
import { MdVerticalAlignBottom } from "react-icons/md";
import { RootContext } from "../../context/RootProvider";

// id-urile incep de la 1, sa nu fie conflicte intre 0 si null

function Interviu() {
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedInterval, setSelectedInterval] = useState(null);
  const [days, setDays] = useState([]);
  const [reserveCounter, setReserveCounter] = useState(0);

  const rootContext = useContext(RootContext);

  const notify = async (interviewId) => {
    try {
      const res = await axios.put(
        `${API_URL}/api/user/${rootContext.user.id}/interview`,
        {
          interview: interviewId,
        }
      );
      if (res.status == 200) toast("Te-ai înregistrat cu succes!");
      setReserveCounter(reserveCounter + 1);
      rootContext.setUser({ ...rootContext.user, interviewId: interviewId });
      setSelectedInterval(null);
    } catch (err) {
      toast(err.response);
    }
  };

  const daysOfWeek = [
    "Duminica",
    "Luni",
    "Marți",
    "Miercuri",
    "Joi",
    "Vineri",
    "Sâmbătă",
  ];

  useEffect(() => {
    async function fetchIntervals() {
      const res = await axios.get(`${API_URL}/api/interview/`);
      const resIntervals = res.data;

      console.log(resIntervals);
      const daysTemp = {};

      resIntervals.forEach((interval) => {
        const date = new Date(interval.date);
        const dayName = daysOfWeek[date.getUTCDay()];
        const time = date.toISOString().split("T")[1].slice(0, 5); // Extracting HH:MM

        if (!daysTemp[dayName]) {
          daysTemp[dayName] = {
            name: dayName,
            intervals: [],
            id: date.getUTCDay(),
          };
        }

        daysTemp[dayName].intervals.push({
          time: time,
          id: interval.id,
          availableSpots: interval.availableSpots,
        });
      });
      setDays(Object.values(daysTemp));
    }
    fetchIntervals();
  }, [reserveCounter]);

  const handleSelectDay = (id) => {
    setSelectedDay(id);
  };

  const handleSelectInterval = (id) => {
    setSelectedInterval(id);
  };

  return (
    <div className={style.interviews}>
      <img className={style.Title} src={Title} alt="Interviuri" />

      <div className={style.interview_select}>
        <div className={style.container}>
          <button className={style.main_button}>Alege o zi</button>

          <ul className={style.options_list}>
            {days.map((day) => (
              <li key={day.id}>
                <button
                  onClick={() => handleSelectDay(day.id)}
                  className={`${style.button} ${
                    selectedDay === day.id ? style.button_active : ""
                  }`}
                >
                  {day.name}
                </button>
              </li>
            ))}
          </ul>
        </div>

        {selectedDay ? (
          <div className={style.container}>
            <button className={style.main_button}>Alege un interval</button>

            <ul className={style.options_list}>
              {days
                .find((day) => day.id === selectedDay)
                .intervals.map((interval) => (
                  <li key={interval.id}>
                    <button
                      onClick={() => handleSelectInterval(interval.id)}
                      disabled={!interval.availableSpots}
                      className={`${style.button} ${
                        selectedInterval === interval.id
                          ? style.button_active
                          : ""
                      } ${
                        interval.availableSpots == 0
                          ? style.button_disabled
                          : ""
                      }`}
                    >
                      {interval?.time}
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        ) : null}
      </div>

      {selectedDay && selectedInterval ? (
        <button
          onClick={() => notify(selectedInterval)}
          className={`${style.main_button} ${style.cta_button}`}
        >
          Rezervă
        </button>
      ) : null}
      <ToastContainer />
    </div>
  );
}

export default Interviu;
