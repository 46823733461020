import React, { useEffect, useState } from "react";
import style from "./Footer.module.css";
import Profile from "../../Pages/Profile/Profile";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { RootContext } from "../../context/RootProvider";
import { useContext } from "react";
import { FaPen, FaFileCode, FaGrinAlt } from "react-icons/fa";

function Footer() {
  const { user } = useContext(RootContext);

  return (
    <div className={style.footer}>
      {
        // <div className={style.navItem}>
        //   <Link to="/interviu" className={style.navLink}>
        //     Interviu
        //     <FaPen className={style.icon} />
        //   </Link>
        // </div>
      }

      <div className={style.navItem}>
        <Link to="/" className={style.navLink}>
          Profil
          <FaGrinAlt className={style.icon} />
        </Link>
      </div>

      {/* <div className={style.navItem}>
        <Link to="/test-tehnic" className={style.navLink}>
          Test Tehnic
          <FaFileCode className={style.icon} />
        </Link>
      </div> */}
    </div>
  );
}

export default Footer;
