import React from "react";
import styles from "./Profile.module.css";
import ContentProfile from "../../Components/ContentProfile/ContentProfile";

import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { RootContext } from "../../context/RootProvider";
import { useEffect } from "react";

function Profile() {
  const rootContext = useContext(RootContext);

  const navigate = useNavigate();

  const userIsAdmin = rootContext.isUserAdmin;

  useEffect(() => {
    if (userIsAdmin) {
      navigate("/admin");
    }
  }, [navigate, userIsAdmin]);

  return (
    <div className={styles.contentPage}>
      <ContentProfile />
    </div>
  );
}

export default Profile;
