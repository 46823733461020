import React, { useContext } from "react";

import Profile from "../Pages/Profile/Profile";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Admin from "../Pages/AdminPages/Admin/Admin";
import ViewResults from "../Pages/AdminPages/ViewResults/ViewResults";
import Interviu from "../Pages/Interviu/Interviu";
import TesteTehnice from "../Pages/TesteTehnice/TesteTehnice";
import LandingPage from "../Pages/LandingPage/LandingPage";
import SignIn from "../Pages/SignIn/SignIn";
import Register from "../Pages/Register/Register";
import ChangePassword from "../Pages/ChangePassword/ChangePassword";

import { RootContext } from "../context/RootProvider";

import Wrapper from "../Components/Wrapper/Wrapper";
import AdminWrapper from "../Components/Wrapper/AdminWrapper";
import LoadTests from "../Pages/AdminPages/LoadTests/LoadTests";
import UserTestContainer from "../Pages/TesteTehnice/UserTestContainer";
import ViewBooked from "../Pages/AdminPages/ViewBooked/ViewBooked";
import TestFinished from "../Pages/TesteTehnice/TestFinished";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import StopRegister from "../Pages/StopRegister/StopRegister";
import TestTehnic from "../Pages/TesteTehnice/TestTehnic";

function Paths() {
  const rootContext = useContext(RootContext);

  const user = rootContext.user;
  const isUserAdmin = rootContext.isUserAdmin;

  if (user?.isCompleteProfile) {
    return (
      <>
        <Router>
          <Routes>
            <Route path="/" element={<Wrapper />}>
              <Route index element={<Profile />} />
              <Route path="/profile" element={<Profile />} />
              {/* <Route path="/interviu" element={<Interviu />} /> */}
              {/* <Route path="/test-tehnic" element={<TestTehnic/>} /> */}
              <Route path="/landing" element={<LandingPage />} />
            </Route>

            <Route
              path="changePassword/:resetToken"
              element={<ChangePassword />}
            />
            <Route
              path="teste-tehnice/:userID/:testID"
              element={<UserTestContainer />}
            />
            <Route
              path="teste-tehnice/:userID/:testID/completat"
              element={<TestFinished />}
            />

            {isUserAdmin && (
              <Route path="/admin" element={<AdminWrapper />}>
                <Route index element={<Admin />} />
                <Route path="view-results" element={<ViewResults />} />
                <Route path="view-booked" element={<ViewBooked />} />
                <Route path="load-tests" element={<LoadTests />} />
              </Route>
            )}
          </Routes>
        </Router>
      </>
    );
  } else {
    return (
      <>
        <Router>
          <Routes>
            <Route path="/landing" element={<LandingPage />} />
            {/* <Route path="/profile" element={<Register />} /> */}
            <Route path="/profile" element={<StopRegister />} />
            <Route path="/*" element={<LandingPage />} />
          </Routes>
        </Router>
      </>
    );
  }
}

export default Paths;
