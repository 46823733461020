import axios from "axios";
import { API_URL } from "../const";

export async function registerUser(CV, userData) {
  let formData = new FormData();
  formData.append("cv", CV);
  formData.append("userData", JSON.stringify(userData));

  return axios(`${API_URL}/api/user/completeRegister`, {
    method: "patch",
    withCredentials: true,
    data: formData,
  });
}

export async function logInUser(userData) {
  const response = await axios(`${API_URL}/api/user/login`, {
    method: "post",
    withCredentials: true,

    data: {
      email: userData.email,
      password: userData.password,
    },
  });
  const { token } = response.data;
  localStorage.setItem("token", token);

  return response.data.user;
}

export async function getUsers() {
  const response = await axios.get(`${API_URL}/api/user/getAllUsers`, {
    method: "get",
    withCredentials: true,
  });

  return { users: response.data, numberOfUsers: response.data.length };
}

export async function changeCV(userData) {
  const payload = new FormData();
  
  const user = {
    id: userData.id,
    lastName: userData.lastName,
    firstName: userData.firstName
  }

  payload.append("userData", JSON.stringify(user));
  payload.append("cv", userData.cv);

  return axios(`${API_URL}/api/user/cv/updateCV/`, {
    method: "post",
    withCredentials: true,
    data: payload,
  });
}

export async function downloadCVs() {
  const response = await axios.get(`${API_URL}/api/archive/downloadCVs`, {
    responseType: "blob",
  });

  return response.data;
}

export async function resetPassword(newPassword, resetToken) {
  await axios(`${API_URL}/api/user/reset-password/${resetToken}`, {
    method: "post",
    withCredentials: true,

    data: {
      password: newPassword,
    },
  });
}

export async function postTest(testData) {
  await axios(`${API_URL}/api/test/createFull`, {
    method: "post",
    withCredentials: true,

    data: testData,
  });
}

export async function getTests() {
  const response = await axios(`${API_URL}/api/test`, {
    method: "get",
    withCredentials: true,
  });

  return response.data;
}

export async function testTaken(userId, testId) {
  let isTestTaken = false;

  try {
    const foundTest = await axios(
      `${API_URL}/api/userTest/${userId}/${testId}/taken`,
      {
        method: "get",
        withCredentials: true,
      }
    );

    if (foundTest.data === true) isTestTaken = true;
  } catch (err) {
    isTestTaken = false;
  }
  return isTestTaken;
}

export async function postUserTest(userTestData) {
  await axios(
    `${API_URL}/api/userTest/${userTestData.userId}/${userTestData.testId}`,
    {
      method: "post",
      withCredentials: true,
      data: userTestData,
    }
  );
}

export async function getScheduledInterviews(){
  try{
    const response = await axios.get(`${API_URL}/api/user/schedule-interview`,{
      method: "get",
      withCredentials: true,
    });
    return response.data;
  } catch(error) {
    console.error("Error fetching scheduled interviews:", error);
    throw error;
  }
}
