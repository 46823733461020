import React, { useContext, useEffect, useRef, useState } from "react";
import { FaDochub, FaDownload } from "react-icons/fa";
import userImg from "../../assets/images/userTest.jpeg";
import { RootContext } from "../../context/RootProvider";
import { changeCV } from "../../utils/apiCalls";
import style from "./ContentProfile.module.css";
import { API_URL } from "../../const";
import axios from "axios";

function ContentProfile() {
  const rootContext = useContext(RootContext);
  const [user, setUser] = useState(null);
  const [interview, setInterview] = useState("");
  const [CV, setCV] = useState(null);

  const timeoutIDRef = useRef();

  const CVChangedMessage = CV
    ? `CV-ul a fost schimbat cu succes (${CV.name})`
    : "CV-ul trebuie sa existe. (CV-ul nu a fost schimbat)";

  useEffect(() => {
    setUser(rootContext.user);
    async function fetchInterview() {
      const res = await axios.get(
        `${API_URL}/api/interview/${rootContext.user.interviewId}`
      );
      const interviewDate = new Date(res.data.date);
      interviewDate.setHours(interviewDate.getHours() - 2);
      res.data.date = interviewDate;

      setInterview(res.data);
    }
    fetchInterview();
  }, []);

  async function onChangeCV(CVData) {
    clearTimeout(timeoutIDRef.current);

    const userData = {
      cv: CVData,
      lastName: user?.lastName,
      firstName: user?.firstName,
      id: user?.id,
      interviewId: user?.interviewId,
    };
    if (CVData) {
      const rezultat = await changeCV(userData);
    }

    setCV(CVData);

    timeoutIDRef.current = setTimeout(() => {
      setCV(null);
    }, 7500);
  }

  return (
    <div className={style.content}>
      <div>
        <img className={style.profilePicture} src={userImg} alt="user-image" />
        <div className={style.userInfo}>
          <h2>
            <span>{user?.lastName}</span> <span>{user?.firstName}</span>
          </h2>
          <p>
            <span>Universitate: </span> <span>{user?.university}</span>
          </p>
          <p>
            <span>Mail: </span> <span>{user?.email}</span>
          </p>
          <p>
            <span>Telefon: </span> <span>{user?.phoneNumber}</span>
          </p>
          <p>
            <span>Interviu:</span>{" "}
            <span>
              {interview == ""
                ? "Neprogramat!"
                : new Date(interview.date).toLocaleString("ro-RO", {
                    hour: "2-digit",
                    minute: "2-digit",
                    day: "numeric",
                    month: "long",
                    weekday: "long",
                  })}
            </span>
          </p>

          <div className={style.buttonZone}>
            {/* <div className={style.inputCV}>
              <input onChange={(e) => onChangeCV(e.target.files[0])} name="CV" id="CV" type="file" accept="application/pdf" />
              <label htmlFor="CV" className={`${style.align} ${style.btnCv}`}>
                <FaDochub fontSize={20} />
                <br />
                <p className={style.textButtons}>Schimbă CV-ul</p>
              </label>
            </div> */}

            <div className={style.inputCV}>
              <a
                className={`${style.align} ${style.btnCv}`}
                href={`${API_URL}/api/user/cv/${user?.id}`}
                download
              >
                <FaDownload fontSize={20} />
                <p className={style.textButtons}>Descarcă CV-ul</p>
              </a>
            </div>
          </div>

          {/* <h3 className={style.important}>Testul tehnic durează maxim 30 de minute, poate fi dat o singură dată și trebuie dat până pe 17.03.2024.</h3> */}

          <p
            className={`${style.confirmCVMessage} ${
              CV !== null ? style.confirmCVActive : style.confirmCVChanged
            }`}
          >
            {CVChangedMessage}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ContentProfile;

// {/* <Link to={`changePassword/${user?.resetToken}`}>
//   <Button className="btnChangePassword">
//     <div className={style.align}>
//       <FaLock fontSize={20} />
//       <br />
//       <p className={style.textButtons}>Schimbă Parola</p>
//     </div>
//   </Button>
// </Link> */}
