import React, {useEffect, useState} from "react";

import styles from "./InterviewTable.module.css";

import {Dropdown} from "primereact/dropdown";

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

import {rowsToDisplay} from "../../../const";

import date from "date-and-time";
import useAllSelectedInterviews from "../../../hooks/useAllIntervals";
import { getScheduledInterviews } from "../../../utils/apiCalls";


const loadInterviews = async (interviewsData) => {
  if (interviewsData === null || interviewsData === undefined) return null;

  let initialInterviews = [...interviewsData];

  return initialInterviews.map((interview) => {
    const now = new Date();
    if (new Date(interview.interview.date) > now) {
      let day = date.format(new Date(interview.interview.date), "DD/MM/YYYY");
      let hour = date.format(new Date(interview.interview.date), "HH:mm");

      return { ...interview, date: day, ora: hour };
    }
    return interview;
  });
};

function InterviewTable() {
  const [interviews, setInterviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState(6);
  const [firstRowIndex, setFirstRowIndex] = useState(0);

  // const rowData = useAllSelectedInterviews();
  // const currentData = rowData?.map((oldElement) => {
  //   return {
  //     nume: oldElement.users[0].firstName,
  //     prenume: oldElement.users[0].lastName,
  //     email: oldElement.users[0].email,
  //     data: new Date(oldElement.data),
  //     ora: oldElement.interval,
  //   };
  // });

  // const loadData = async () => {
  //   const interviewsData = await loadInterviews(currentData);
  //   let currentInterviews = [];

  //   if (interviewsData) {
  //     for (const element of interviewsData) {
  //       if (element) {
  //         currentInterviews.push(element);
  //       }
  //     }
  //     setInterviews(currentInterviews);
  //     setIsLoading(false);
  //   }

  // };

  // useEffect(() => {
  //   loadData();
  // }, [rowData]);


  // const onPage = (event) => {
  //   const startIndex = event.first;
  //   setFirstRowIndex(startIndex);
  // };

  const loadData = async () => {
    try {
      const interviewsData = await getScheduledInterviews();
      console.log("Fetched Interviews Data:", interviewsData);
      const formattedInterviews = await loadInterviews(interviewsData);
      console.log("Formatted Interviews Data:", formattedInterviews);
      let currentInterviews = [];

      if (formattedInterviews) {
        for (const element of formattedInterviews) {
          if (element) {
            currentInterviews.push(element);
          }
        }
        setInterviews(currentInterviews);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error loading interviews:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const onPage = (event) => {
    const startIndex = event.first;
    setFirstRowIndex(startIndex);
  };

  return (
    <div className={styles.tableContainer}>
      <div className={styles.dropdownContainer}>
        <p>Select the number of rows to display</p>

        <Dropdown
          optionLabel="rows"
          value={rows}
          optionValue="rows"
          options={rowsToDisplay}
          onChange={(event) => {
            setRows(event.value);
            setFirstRowIndex(0);
          }}
          placeholder="Rows to display"
        />
      </div>

      <DataTable
        value={interviews}
        loading={isLoading}
        paginator
        rows={rows}
        first={firstRowIndex}
        onPage={onPage}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} interviews"
      >
        <Column field="lastName" filter header="Nume"></Column>
        <Column field="firstName" filter header="Prenume"></Column>
        <Column field="date" header="Data" sortable dataType="date"></Column>
        <Column field="ora" header="Ora" sortable></Column>
      </DataTable>
    </div>
  );
}

export default InterviewTable;
