import React from 'react'
import style from './StopRegister.module.css'

export default function StopRegister() {
  return (
    <div className={style.mainContainer}>
      <h1>Înscrierile au luat sfârșit</h1>
      <h2>Rămâneți cu ochii pe <a href="https://www.instagram.com/sisc.ttj/" target='_aboutblank'>social media</a> și pe pagina de profil din aplicație pentru informații despre următoarele etape!</h2>
    </div>
  )
}
