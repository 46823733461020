import React from "react";

import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../const";

export const RootContext = React.createContext();

function RootProvider({ children }) {
  // NU UITA SA STERGI ASTA CAND E GATA FRONTUL 👍👍👍👍👍

  const [user, setUser] = useState(
    //     {
    //     id: 1,
    //     firstName: 'Mugur',
    //     lastName: 'Vlad',
    //     phoneNumber: '+40720140251',
    //     email: 'mugurvlad47@gmail.com',
    //     university: 'ASE',
    //     facebook: 'https://facebook.com/mugurvlad47',
    //     facultyYear: 2,
    //     facultySpecialization: 'Salahor Central',
    //     isPreRegistered: true
    // }
    null
  );

  useEffect(() => {
    const fetchUser = async () => {
      const user = await axios.get(`${API_URL}/api/user/getCurrentUser`, {
        withCredentials: true,
      });
      console.log("useState", user);
      setUser(user.data);
    };
    fetchUser();
  }, []);

  // const [ user, setUser ] = useState(null);
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  const context = {
    user: user,
    setUser: setUser,
    isUserAdmin: isUserAdmin,
    setIsUserAdmin: setIsUserAdmin,
  };

  return (
    <RootContext.Provider value={context}>{children}</RootContext.Provider>
  );
}

export default RootProvider;
