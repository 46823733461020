import React from "react";
import { GrLogout } from "react-icons/gr";
import Logo from "../../assets/images/Siglă-bej-wide-fundal-mov.png";
import style from "./Navbar.module.css";

import { RootContext } from "../../context/RootProvider";
import { useContext } from "react";

import { useNavigate } from "react-router-dom";

function Navbar() {
  const rootContext = useContext(RootContext);

  const navigate = useNavigate();

  function handleLogout() {
    rootContext.setUser(null);
    rootContext.setIsUserAdmin(false);

    navigate("/");
  }

  return (
    <nav className={style.navContainer}>
      <a
        className={`${style.website} ${style.onHover}`}
        href="https://ttj.sisc.ro/"
      >
        Înapoi
      </a>

      <div className={style.logoContainer}>
        <img className={style.logo} src={Logo} alt="ttj" />
        <div className={style.icon}></div>
      </div>

      <div
        onClick={handleLogout}
        className={`${style.container} ${style.onHover}`}
      >
        <p>Logout</p>
      </div>
    </nav>
  );
}

export default Navbar;
